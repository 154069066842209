var ABTestDownloadIDs = ABTestDownloadIDs || (function(){
	'use strict';

	var parameters = {};

	return {
		init: function(params) {
			// default data-role="download-link"
			parameters = params;
		},
		run: function() {
			try {
				var testDownloadID = parameters.IDSuffix,
					buttons = document.querySelectorAll(parameters.query),
					buttonsArray = [];

				// array of buttons object
				buttons.forEach(function(button) {
					setButton(button);
				})

				// set button's default and test parameters and push it into buttonsArray
				function setButton(button) {
					// set direct=1 parameter for direct download using download popup
					if ((!button.getAttribute('data-behavior') || button.getAttribute('data-behavior') && button.getAttribute('data-behavior').toLowerCase() !== 'downloadpage') && !avastGlobals.detect.os.isAndroid && !avastGlobals.detect.os.isIOS) {
						var url = new URL(button.href),
							params = url ? url.searchParams : null;

						params.set('direct', 1);
						url.search = params.toString();
						button.href = url.toString();
					}

					var tempID = button.getAttribute('data-download-name'),
						tempHref = button.getAttribute('href');

					buttonsArray.push({
						'el': button,
						'defaultID': tempID.search(testDownloadID) === -1 ? tempID : tempID.replace(testDownloadID, ''),
						'defaultHref': tempHref.search(testDownloadID) === -1 ? tempHref : tempHref.replace(testDownloadID, ''),
						'testID': tempID.search(testDownloadID) === -1 ? tempID + testDownloadID : tempID,
						'testHref': tempHref.search(testDownloadID) === -1 ? tempHref.replace(tempID, tempID + testDownloadID) : tempHref
					})
				};

				// check for
				function checkConsent(btnArray) {
					// check for cookie consent, then replace download IDs with testing one, else replace it with default one
					if (window.OnetrustActiveGroups && window.OnetrustActiveGroups.includes('C0002')) {
						btnArray.forEach(function(buttonObj) {
							buttonObj.el.setAttribute('href', buttonObj.testHref);
							buttonObj.el.setAttribute('data-bi-download-name', buttonObj.testID);
							buttonObj.el.setAttribute('data-download-name', buttonObj.testID);
						});
					} else {
						btnArray.forEach(function(buttonObj) {
							buttonObj.el.setAttribute('href', buttonObj.defaultHref);
							buttonObj.el.setAttribute('data-bi-download-name', buttonObj.defaultID);
							buttonObj.el.setAttribute('data-download-name', buttonObj.defaultID);
						});
					}
				}

				// run consent check on load
				checkConsent(buttonsArray);

				// listener for custom event from cookie consent banner
				window.addEventListener('OneTrustGroupsUpdated', function() {checkConsent(buttonsArray)});

				// listener for pricebox update
				window.addEventListener('pricebox-updated', function(event) {
					event.detail.element.querySelectorAll(parameters.query).forEach(function(button){
						setButton(button);
						checkConsent(buttonsArray);
					})
				});


			} catch (error) {
				// singe data layer error tracking
				if (typeof sdl !== 'undefined') {
					console.error(error);
					sdl.push({
						system: {
							error: {
								type: error.name,
								message: error.message,
							}
						},
						event: 'system.error'
					})
				}
			}
		}
	};
}());
